import axios from "axios";
import Configuration from "@/services/configuration";
import {store} from "@/store/store";

const httpAuthClient = axios.create({
    baseURL: Configuration.value('authUrl'),
    timeout: 20000,
    withCredentials: true
});


httpAuthClient.interceptors.response.use(response => {
    return response;
}, (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (error?.response?.status !== 401) {
        return Promise.reject(error);
    }

    // Logout user if token refresh didn't work or user is disabled
    if (error.config.url.endsWith('/token')) {
        store.dispatch("auth/logout");
        window.location.href = Configuration.value('loginUrl');
    }
    return Promise.reject(error);

});


const END_POINT = '/api/v1';

const signin = (user:any) => httpAuthClient.post(`${END_POINT}/login`,{
    username: user.username,
    password: user.password
});

const token = () => httpAuthClient.post(`${END_POINT}/token`);

const logout = () => httpAuthClient.post(`/logout`);

const impersonate = (userId:string) => httpAuthClient.post(`${END_POINT}/admin/impersonate`,{
    targetUserId: userId
});

const stopImpersonate = () => httpAuthClient.post(`${END_POINT}/admin/impersonate/stop`);

export {
    logout,
    signin,
    token,
    impersonate,
    stopImpersonate
}
