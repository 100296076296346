import dotenv from 'dotenv'
dotenv.config()

export default class Configuration {
    static get CONFIG () {
        return {
            authUrl: '$VUE_APP_AUTH_BASE_URL',
            backendUrl: '$VUE_APP_API_BASE_URL',
            loginUrl: '$VUE_APP_LOGIN_URL',
            dashboardUrl: '$VUE_APP_DASHBOARD_BASE_URL',
            appBarColor: '$VUE_APP_BAR_COLOR',
        }
    }

    static value (name) {
        if (!(name in this.CONFIG)) {
            return
        }

        const value = this.CONFIG[name]

        if (!value) {
            return
        }

        if (value.startsWith('$VUE_APP_')) {
            // value was not replaced, it seems we are in development.
            // Remove $ and get current value from process.env
            const envName = value.substr(1)
            const envValue = process.env[envName]
            if (envValue) {
                return envValue
            }
        } else {
            // value was already replaced, it seems we are in production.
            return value
        }
    }
}
